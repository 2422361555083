import { useEffect } from 'react';

const useRunOnce = (fn) => {
    useEffect(() => {
        if (typeof fn === 'function') {
            fn();
        }
    }, []); // Empty dependency array ensures it runs only once
};
export default useRunOnce;
