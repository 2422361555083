import React, { useState, useRef, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import './Uploader.css';

const Uploader = ({ onNavigateToView, url_upload }) => {
    const [isRunning, setIsRunning] = useState(false);
    const [files, setFiles] = useState([]);
    const [fileOptions, setFileOptions] = useState({});
    const [acknowledgment, setAcknowledgment] = useState('');
    const abortControllerRef = useRef(null);
    const fileInputRef = useRef(null);
    const currentXhrRef = useRef(null); // New ref for the current XHR
    const handleRemoveFile = (fileName) => {
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
        setFileOptions(prevOptions => {
            const { [fileName]: _, ...rest } = prevOptions;
            return rest;
        });
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };
    const handleClear = () => {
        setIsRunning(false);
        setAcknowledgment('');
        setFiles([]);
        setFileOptions({});
    };
    const handleFileChange = (e) => {
        const newFiles = [...e.target.files];
        let duplicateFound = false;
        let invalidFileFound = false;
        const newFileOptions = { ...fileOptions };
        const allowedExtensions = ['.mp3', '.wav', '.doc', '.docx', '.pdf'];
        const uniqueFiles = newFiles.filter(newFile => {
            const fileExtension = newFile.name.slice(((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
            const isAllowed = allowedExtensions.includes(`.${fileExtension}`);
            if (!isAllowed) {
                invalidFileFound = true;
                setAcknowledgment(prev => `${prev} Invalid file type: ${newFile.name}`);
                return false;
            }
            const isDuplicate = files.some(file => file.name === newFile.name);
            if (isDuplicate) {
                duplicateFound = true;
                setAcknowledgment('Duplicate files were detected and not added.');
                return false; // Exclude duplicate file
            }
            newFileOptions[newFile.name] = newFileOptions[newFile.name] || {
                transcribe: false,
                translate: false,
                target_language: '',
                progress: 0
            };
            return true; // Include unique file
        });

        const uploadedFiles = [...files, ...uniqueFiles];
        setFiles(uploadedFiles);
        setFileOptions(newFileOptions);

        if (invalidFileFound) {
            setAcknowledgment(prev => 'Rejected due to invalid file types.');
        } else {
            setAcknowledgment(duplicateFound ? 'Duplicate files were detected and not added.' : '');
        }

        // Reset file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };


    const handletarget_languageChange = (fileName, target_language) => {
        setFileOptions(prevOptions => ({
            ...prevOptions,
            [fileName]: {
                ...prevOptions[fileName],
                target_language: target_language
            }
        }));
    };

    const handleCheckboxChange = (fileName, option) => {
        setFileOptions(prevOptions => ({
            ...prevOptions,
            [fileName]: {
                ...prevOptions[fileName],
                [option]: !prevOptions[fileName][option]
            }
        }));
    };

    const handleFileSubmit = async (e) => {
        e.preventDefault();
        setAcknowledgment('Process started...');
    
        if (files.length === 0) {
            setAcknowledgment('ERROR: No files selected');
            return;
        }
    
        abortControllerRef.current = new AbortController();
    
        // Check if options are valid for all files
        const allOptionsValid = files.every(file => {
            const options = fileOptions[file.name];
            const hasOperation = options.transcribe || options.translate;
            const hasTargetLanguage = options.translate ? !!options.target_language : true; // Only check target language if translating
            return hasOperation && hasTargetLanguage;
        });
    
        if (!allOptionsValid) {
            setAcknowledgment('ERROR: Please select operation and target language for all files.');
            return;
        }
    
        try {
            setIsRunning(true);
    
            for (const file of files) {
                const options = fileOptions[file.name];
                const operation =
                    options.transcribe && options.translate ? 'transcribe-translate' :
                    options.transcribe ? 'transcribe' :
                    options.translate ? 'translate' : '';
    
                const formData = new FormData();
                formData.append('files', file);
                formData.append('operations', operation);
                formData.append('target_language', options.target_language || '');
    
                // Create a new XMLHttpRequest for each file
                const xhr = new XMLHttpRequest();
                currentXhrRef.current = xhr; // Store current XHR in the ref
                xhr.open('POST', `${url_upload}/upload`, true);
    
                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const total = event.total;
                        const loaded = event.loaded;
                        const progress = Math.round((loaded / total) * 100);
    
                        setFileOptions(prevOptions => {
                            const newOptions = { ...prevOptions };
                            newOptions[file.name].progress = progress;
                            return newOptions;
                        });
                    }
                };
    
                xhr.onload = async () => {
                    if (xhr.status === 200) {
                        const result = JSON.parse(xhr.responseText);
                        setAcknowledgment(`Success: ${result.message}`);
                    } else {
                        setAcknowledgment(`Error: ${xhr.statusText}`);
                    }
                };
    
                xhr.onerror = () => {
                    setAcknowledgment('Error: Request failed');
                };
    
                // Check if the request was aborted
                xhr.onabort = () => {
                    setAcknowledgment('Upload stopped.');
                };
    
                xhr.send(formData);
                
                // Optionally wait for this request to finish before moving to the next
                await new Promise((resolve) => {
                    xhr.onloadend = resolve;
                });
            }
        } catch (error) {
            setAcknowledgment(`Error: ${error.message}`);
            handleHalt();
        } finally {
            setIsRunning(false);
        }
    };
    

    const handleHalt = () => {
        // Abort the ongoing fetch request
        if (currentXhrRef.current) {
            currentXhrRef.current.abort();
        }
        setIsRunning(false);
    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            if (currentXhrRef.current) {
                currentXhrRef.current.abort();
            }
        };
    }, []);
    return (
        <div className="App">
            <nav>
                <button onClick={onNavigateToView}>View Processed Files</button>
            </nav>
            <h1>Upload Files</h1>
            <form>
                <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
                {!isRunning && <button onClick={handleFileSubmit}>Start</button>}
                {isRunning && <button onClick={handleHalt}>Stop</button>}
                {!isRunning && <button onClick={handleClear}>Clear</button>}
            </form>
            {isRunning && <FaSpinner className="spinner" />}
            {acknowledgment && <p style={{ fontSize: '20px', color: 'white' }}>{acknowledgment}</p>}
            {files.length > 0 && (
                <div>
                    <h2>File Options</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>File</th>
                                <th>Transcribe</th>
                                <th>Translate</th>
                                <th>Target Language</th>
                                <th>Upload Progress</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file) => (
                                <tr key={file.name}>
                                    <td>{file.name}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={fileOptions[file.name]?.transcribe || false}
                                            onChange={() => handleCheckboxChange(file.name, 'transcribe')}
                                            disabled={isRunning}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={fileOptions[file.name]?.translate || false}
                                            onChange={() => handleCheckboxChange(file.name, 'translate')}
                                            disabled={isRunning}
                                        />
                                    </td>
                                    <td>
                                        {fileOptions[file.name]?.translate && (
                                            <input
                                                type="text"
                                                value={fileOptions[file.name]?.target_language || ''}
                                                onChange={(e) => handletarget_languageChange(file.name, e.target.value)}
                                                placeholder='Enter Target Language'
                                                disabled={isRunning}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        {fileOptions[file.name]?.progress !== undefined && (
                                            <div className="progress-container">
                                                <span>{fileOptions[file.name].progress}%</span>
                                                <progress value={fileOptions[file.name].progress} max="100" />
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {!isRunning && (
                                            <button onClick={() => handleRemoveFile(file.name)}>Remove</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Uploader;

